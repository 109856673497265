import { MDXProvider } from "@mdx-js/react";

import City from "./mdx/City";
import Wrapper from "./mdx/Wrapper";
import Sekcja from "./mdx/Sekcja";
import Row from "./mdx/Row";
import Column from "./mdx/Column";
import Box from "./mdx/Box";
import Images from "./mdx/Images";
import LinkTo from "./mdx/LinkTo";
import YouTube from "./mdx/YouTube";
import TaxYear from "./mdx/TaxYear";

import Aktualizacja from "./mdx/accessibility/Aktualizacja";
import Architektura from "./mdx/accessibility/Architektura";
import Email from "./mdx/accessibility/Email";
import Kontakt from "./mdx/accessibility/Kontakt";
import Osoba from "./mdx/accessibility/Osoba";
import Podmiot from "./mdx/accessibility/Podmiot";
import Procedura from "./mdx/accessibility/Procedura";
import Przeglad from "./mdx/accessibility/Przeglad";
import Publikacja from "./mdx/accessibility/Publikacja";
import Sporzadzenie from "./mdx/accessibility/Sporzadzenie";
import Status from "./mdx/accessibility/Status";
import Telefon from "./mdx/accessibility/Telefon";
import Url from "./mdx/accessibility/Url";
import Wstep from "./mdx/accessibility/Wstep";

import UpperText from "./mdx/text/UpperText";
import AsideText from "./mdx/text/Aside";
import TextColor from "./mdx/text/TextColor";
import P1 from "./mdx/text/P1";
import P2 from "./mdx/text/P2";
import P3 from "./mdx/text/P3";
import H2 from "./mdx/text/H2";
import H3 from "./mdx/text/H3";
import H4 from "./mdx/text/H4";

import Section from "./Section";

import FAQ from "./FAQ";
import FloatVideo from "./FloatVideo";

function getAnchor(text) {
	return text
		.toLowerCase()
		.replace(/-/g, "")
		.replace(/–/g, "")
		.replace(/[?]/g, "")
		.replace(/[!]/g, "")
		.replace(/[ą]/g, "a")
		.replace(/[ć]/g, "c")
		.replace(/[ę]/g, "e")
		.replace(/[ł]/g, "l")
		.replace(/[ń]/g, "n")
		.replace(/[ó]/g, "o")
		.replace(/[ś]/g, "s")
		.replace(/[ź]/g, "z")
		.replace(/[ż]/g, "z")
		.replace(/[ ]/g, "-")
		.replace(/--/g, "-")
		.replace(/––/g, "-");
}

const MDX = (props) => {
	return (
		<MDXProvider
			components={{
				props,
				h2: (props) => <h2 {...props} className="pd-panel__title" />,
				h3: (props) => (
					<h3
						{...props}
						className="pd-panel__header--h3"
						// id={getAnchor(props.children)}
					/>
				),
				h4: (props) => <h4 {...props} className="pd-panel__header--h4" />,
				p: (props) => <p {...props} className="pd-panel__text" />,
				ul: (props) => <ul {...props} className="pd-custom-list" />,
				ol: (props) => <ol {...props} className="pd-custom-list" />,
				Sekcja,
				Wrapper,
				City,
				Row,
				Column,
				Box,
				Images,
				LinkTo,
				YouTube,
				TaxYear,
				Aktualizacja,
				Architektura,
				Email,
				Kontakt,
				Osoba,
				Podmiot,
				Procedura,
				Przeglad,
				Publikacja,
				Sporzadzenie,
				Status,
				Telefon,
				Url,
				Wstep,
				UpperText,
				AsideText,
				TextColor,
				P1,
				P2,
				P3,
				H2,
				H3,
				H4,
				Section,
				FAQ,
				FloatVideo,
			}}
		>
			{props.children}
		</MDXProvider>
	);
};

export default MDX;
