let packageJson = require("../../../package.json");

export default function Aktualizacja() {
	const date = packageJson.update;

	return (
		<time id="a11y-data-aktualizacja" dateTime={date}>
			<strong>{date}</strong>
		</time>
	);
}
